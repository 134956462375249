import { createContext } from 'react';
import { useImmerReducer } from "use-immer";
import { DISPATCH as TYPE } from "consts/chat.consts";
import { DateTime } from 'luxon';
import newConversationSound from 'design/assets/sounds/cta/new-conversation.wav';
import newMessageSound from 'design/assets/sounds/cta/new-message.wav';

const ChatContext = createContext();
export default ChatContext;

const playNewConversationSound = () => {
  new Audio(newConversationSound).play();
}

const playNewMessageSound = () => {
  new Audio(newMessageSound).play();
}

const reducer = (state, action) => {
  switch (action.type) {
    case TYPE.USER.SET.TYPE: {
      state.user = action.payload.user;
      break;
    }
    case TYPE.CONTACT.UPDATE.TYPE: {
      if(typeof state.conversationSelected === 'object') {
        if(state.conversationSelected.contact.id === action.payload.contact.id ) {
          Object.assign(state.conversationSelected.contact, action.payload.contact);
        }
        if(Array.isArray(state.conversations)) {
          state.conversations.forEach((conversation) => {
            if(conversation.contact.id === action.payload.contact.id) {
              Object.assign(conversation.contact, action.payload.contact);
            }
          });
        }
      }
      break;
    }
    case TYPE.LINES.ASSIGNED.TYPE: {
      state.lines = action.payload.lines;
      break;
    }
    case TYPE.LINE.SELECTED.TYPE: {
      const lastLineSelectedId = state.lineSelected?.id;
      state.lineSelected = action.payload.line;
      if(state.lineSelected?.id !== lastLineSelectedId) {
        state.agentsFiltered = [];
        state.agentSelected = null;
        state.conversationsFiltered = [];
        state.conversationSelected = null;
        state.eventsFiltered = [];
        state.events = [];
      }
      break;
    }
    case TYPE.AGENTS.ASSIGNED.TYPE: {
      state.agents = action.payload.agents;
      break;
    }
    case TYPE.AGENTS.ASSIGNED.UPDATE.TYPE: {
      state.agents.forEach(agent => {
        if(agent.id === action.payload.agent.id) {
          Object.assign(agent, action.payload.agent);
        }
      });
      if(state.agentSelected?.id === action.payload.agent.id) {
        Object.assign(state.agentSelected, action.payload.agent);
      }
      break;
    }
    case TYPE.AGENTS.ASSIGNED.FILTERED.TYPE: {
      state.agentsFiltered = action.payload.agents;
      break;
    }
    case TYPE.AGENT.SELECTED.TYPE: {
      const lastAgentSelectedId = state.agentSelected?.id;
      state.agentSelected = action.payload.agent;
      if(state.agentSelected?.id !== lastAgentSelectedId) {
        state.conversationsFiltered = [];
        state.conversationSelected = null;
        state.events = [];
        state.eventsFiltered = [];
      }
      break;
    }
    case TYPE.CONVERSATIONS.UNASSIGNED.TYPE: {
      state.conversationsUnassigned = action.payload.conversations;
      break;
    }
    case TYPE.CONVERSATIONS.UNASSIGNED.UPSERT.TYPE: {
      const conversation = state.conversationsUnassigned.find(conversation => {
        if(conversation.lineId === action.payload.conversation.lineId 
          && conversation.contact.phone === action.payload.conversation.contact.phone
        ) {
          Object.assign(conversation, action.payload.conversation);
          return true;
        }
        return false;
      });
      if(conversation === undefined) {
        let addToList = false;
        switch (state.user?.role) {
          case 'AGENT': {
            const line = state.lines.find((line) => line.id === action.payload.conversation.lineId);
            if(line) {
              addToList = line.areasByLines?.includes(action.payload.conversation.areaId);
            }
            break;
          }
          case 'SUPERVISOR': {
            addToList = true;
            break;
          }
          default:
            break;
        }
        if(addToList) {
          state.conversationsUnassigned.push(action.payload.conversation);
        }
      }
      if(state.conversationUnassignedSelected?.lineId === action.payload.conversation.lineId
        && state.conversationUnassignedSelected?.contact?.phone === action.payload.conversation.contact.phone
        ) {
        Object.assign(state.conversationUnassignedSelected, action.payload.conversation);
      }
      break;
    }
    case TYPE.CONVERSATIONS.UNASSIGNED.TIME_ELAPSED_CHECK.TYPE: {
      state.conversationsUnassigned.forEach((conversation) => {
        if(conversation?.timeElapsedLastMessage) {
          delete conversation?.timeElapsedLastMessage;
        }
        const dateLastMessage = DateTime.fromISO(conversation.lastMessageSentAt);
        const diff = DateTime.now().diff(dateLastMessage, ['hours', 'minutes', 'seconds', 'milliseconds']);
        if(diff.minutes >= 5 || diff.hours >= 1) {
          if(diff.hours >= 24) {
            conversation.timeElapsedLastMessage = '+24:00:00';
          } else {
            if(diff.hours >= 1) {
              conversation.timeElapsedLastMessage = diff.toFormat('h:mm:ss');
            } else {
              conversation.timeElapsedLastMessage = diff.toFormat('mm:ss');
            }
          }
        }
      });
      break;
    }
    case TYPE.CONVERSATIONS.UNASSIGNED.FILTERED.TYPE: {
      state.conversationsUnassignedFiltered = action.payload.conversations;
      break;
    }
    case TYPE.CONVERSATIONS.ASSIGNED.TYPE: {
      state.conversations = action.payload.conversations;
      break;
    }
    case TYPE.CONVERSATIONS.ASSIGNED.UPSERT.TYPE: {
      const conversation = state.conversations.find(conversation => {
        if(conversation.assignedConversationId === action.payload.conversation.assignedConversationId) {
          Object.assign(conversation, action.payload.conversation);
          return true;
        }
        return false;
      });
      if(conversation === undefined) {
        let addToList = false;
        switch (state.user?.role) {
          case 'AGENT': {
            if(state.user.uid === action.payload.user.id) {
              playNewConversationSound();
              addToList = true;
            }
            break;
          }
          case 'SUPERVISOR': {
            addToList = true;
            break;
          } 
          default:
            break;
        }

        if(addToList) {
          state.conversations.push(action.payload.conversation);
        }
      }
      if(state.conversationSelected?.assignedConversationId === action.payload.conversation.assignedConversationId) {
        Object.assign(state.conversationSelected, action.payload.conversation);
      }


      const indexConversationUnassigned = state.conversationsUnassigned.findIndex((conversation) => {
        return conversation.lineId === action.payload.conversation.lineId 
        && conversation.contact.phone === action.payload.conversation.contact.phone;
      })
      if(indexConversationUnassigned !== -1) {
        state.conversationsUnassigned.splice(indexConversationUnassigned, 1);
      }

      if(state.conversationUnassignedSelected?.lineId === action.payload.conversation.lineId
        && state.conversationUnassignedSelected?.contact?.phone === action.payload.conversation.contact.phone
        ) {
          state.conversationUnassignedSelected = null;
      }
      break;
    }
    case TYPE.CONVERSATIONS.ASSIGNED.UPDATE.TYPE: {
      state.conversations.forEach(conversation => {
        if(conversation.assignedConversationId === action.payload.conversation.assignedConversationId) {
          if(conversation.assignedConversationId !== state.conversationSelected?.assignedConversationId) {
            if(conversation?.newMessages < action.payload.conversation?.newMessages) {
              if(state.user?.role === 'AGENT') {
                playNewMessageSound();
              }
            }
          }
          Object.assign(conversation, action.payload.conversation);
        }
      });
      if(state.conversationSelected?.assignedConversationId === action.payload.conversation.assignedConversationId) {
        if(!state.conversationSelected?.isFinished && !state.conversationSelected?.isReassigned) {
          Object.assign(state.conversationSelected, action.payload.conversation);
        }
      }
      break;
    }
    case TYPE.CONVERSATIONS.ASSIGNED.FINISH.TYPE: {
      state.conversations = state.conversations.filter((conversation) => {
        return conversation.assignedConversationId !== action.payload.conversation.assignedConversationId;
      });
      if(state.conversationSelected?.assignedConversationId === action.payload.conversation.assignedConversationId) {
        state.conversationSelected.isFinished = true;
        state.eventsFiltered = [];
        state.events = [];
      }
      break;
    }
    case TYPE.CONVERSATIONS.ASSIGNED.REASSIGNED.TYPE: {
      state.conversations = state.conversations.filter((conversation) => {
        return conversation.assignedConversationId !== action.payload.conversation.assignedConversationId;
      });
      if(state.conversationSelected?.assignedConversationId === action.payload.conversation.assignedConversationId) {
        state.conversationSelected.isReassigned = true;
        state.eventsFiltered = [];
        state.events = [];
      }
      break;
    }
    case TYPE.CONVERSATIONS.ASSIGNED.TIME_ELAPSED_CHECK.TYPE: {
      window.document.title = 'NeoConecta';
      let coundOfMessagePendingRead = 0;

      state.lines.forEach((line) => {if(line.isAlert) {delete line.isAlert}});
      if(state.lineSelected) {if(state.lineSelected.isAlert) {delete state.lineSelected.isAlert}}
      state.agents.forEach((agent) => {if(agent?.detail?.timeout){delete agent?.detail?.timeout}});
      if(state.agentSelected) {if(state.agentSelected?.detail?.timeout){delete state.agentSelected?.detail?.timeout}}
      
      state.conversations.forEach((conversation) => {
        if(conversation?.timeElapsedLastMessage) {
          delete conversation?.timeElapsedLastMessage;
        }
        if(conversation?.lastMessageWasSentByContact && (!conversation?.isReassigned && !conversation?.isFinished)) {
          const dateLastMessage = DateTime.fromISO(conversation.lastMessageSentAt);
          const diff = DateTime.now().diff(dateLastMessage, ['hours', 'minutes', 'seconds', 'milliseconds']);
          if(diff.minutes >= 5 || diff.hours >= 1) {
            if(diff.hours >= 24) {
              conversation.timeElapsedLastMessage = '+24:00:00';
            } else {
              if(diff.hours >= 1) {
                conversation.timeElapsedLastMessage = diff.toFormat('h:mm:ss');
              } else {
                conversation.timeElapsedLastMessage = diff.toFormat('mm:ss');
              }
            }

            state.lines.forEach((line) => {
              if(line.id === conversation.lineId){
                line.isAlert = true
              }
            })
            if(state.lineSelected?.id === conversation.lineId) {
              state.lineSelected.isAlert = true;
            }
            state.agents.forEach((agent) => {
              if(agent.id === conversation.userId){
                agent.detail = agent.detail ?? {}; 
                agent.detail.timeout = true;
              }
            })
            if(state.agentSelected?.id === conversation.userId) {
              state.agentSelected.detail = state.agentSelected.detail ?? {}; 
              state.agentSelected.detail.timeout = true;
            }
          }
        }

        if(conversation?.newMessages) {
          coundOfMessagePendingRead+= conversation?.newMessages;
        }
      });
      
      if(coundOfMessagePendingRead > 0) {
        window.document.title = `(${coundOfMessagePendingRead}) NeoConecta`;
      }
      break;
    }
    case TYPE.CONVERSATIONS.ASSIGNED.FILTERED.TYPE: {
      state.conversationsFiltered = action.payload.conversations;
      break;
    }
    case TYPE.CONVERSATION.SELECTED.TYPE: {
      state.conversationSelected = action.payload.conversation;
      break;
    }
    case TYPE.CONVERSATION.SELECTED.CONTENT.TYPE: {
      state.events = action.payload.content;
      break;
    }
    case TYPE.CONVERSATION.SELECTED.CONTENT.UPSERT.TYPE: {
      const findBy = action.payload.findBy ?? "id";
      const event = state.events.find(event => {
        if(event[findBy] === action.payload.content[findBy]) {
          Object.assign(event, action.payload.content);
          return true;
        }
        return false;
      });
      if(event === undefined) {
        state.events.push(action.payload.content);
      }
      if(action.payload.content.status === "SENDING" && action.payload.conversation) {
        const conversation = state.conversations.find(conversation => {
          if(conversation.assignedConversationId === action.payload.conversation.assignedConversationId) {
            return true;
          }
          return false;
        });
        if(conversation) {
          conversation.lastMessageSentAt = new Date().toISOString();
          conversation.lastMessageWasSentByContact = false;
          conversation.newMessages = 0;
          conversation.newMessagesTest = 0;
          delete conversation?.timeElapsedLastMessage;
        }
      }
      break;
    }
    case TYPE.CONVERSATION.SELECTED.CONTENT.FILTERED.TYPE: {
      state.eventsFiltered = action.payload.content;
      break;
    }
    case TYPE.QUICKLY_REPLIES.ASSIGNED.TYPE: {
      state.quicklyReplies = action.payload.quicklyReplies;
      break;
    }
    case TYPE.QUICKLY_REPLIES.ASSIGNED.FILTERED.TYPE: {
      state.quicklyRepliesFiltered = action.payload.quicklyReplies;
      break;
    }
    default:
      throw Error(`Unknown chat action: ${action.type}`);
  }
};

export function ChatContextProvider(props) {
  const [state, dispatch] = useImmerReducer(reducer, {
    user: null,
    lines: [],
    lineSelected: null,
    agents:[],
    agentsFiltered: [],
    agentSelected: null,
    conversationsUnassigned: [],
    conversationsUnassignedFiltered: [],
    conversationUnassignedSelected: null,
    conversations: [],
    conversationsFiltered: [],
    conversationSelected: null,
    events: [],
    eventsFiltered: [],
    quicklyReplies: [],
    quicklyRepliesFiltered: []
  });

  return (
    <ChatContext.Provider value={{
      state,
      dispatch
    }}
    >
      {props.children}
    </ChatContext.Provider>
  );
}