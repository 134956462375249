import BACKEND from "consts/server.consts";
import { auth } from "config/firebase.config";
import axios from "axios";

/**
 * @param {Object} payload
 * @param {"error"|"info"} level
 */
async function logToBackend(payload, level = "error") {
  if (process.env.NODE_ENV === "development") {
    console.log("Skipping log to backend...", payload);
    return;
  }

  try {
    const authorization = await auth.currentUser.getIdToken();
    const { data, headers } = await axios.post(
      `${BACKEND.BASE_URL}/rest/logs`,
      { payload, level },
      { headers: { authorization } }
    );
    return { success: true, body: data, headers };
  } catch (error) {
    console.log("Could not log to backend");
  }
}

/**
 * @param {Error} error
 * @param {React.ErrorInfo} info
 */
function globalErrorHandler(error, info) {
  const payload = {
    message: error.message,
    stack: error.stack,
    componentStack: info.componentStack,
  };

  logToBackend(payload);
}

function ErrorFallbackComponent() {
  return null;
}

export { logToBackend, globalErrorHandler, ErrorFallbackComponent };
