import "index.scss";
import App from "App";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import {
  ErrorFallbackComponent,
  globalErrorHandler,
} from "utils/errorBoundary.utils";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallbackComponent}
      onError={globalErrorHandler}
    >
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
